import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { Dispatch, FC, MouseEvent, ReactElement, SetStateAction, useState } from 'react';
import { ReactComponent as WorldIcon } from '../../icons/World.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import styles from '../profile/PublicStatusPopover.module.css';

type DefaultPublicStatusPopoverProps = {
  isPublic: boolean;
  claim?: string;
  setClaims: Dispatch<SetStateAction<string[]>>
  claims: string[];
};

export const DefaultPublicStatusPopover: FC<DefaultPublicStatusPopoverProps> = ({
  isPublic,
  claim,
  setClaims,
  claims,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleButtonClick = async (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    closePopover(event);

    if (claim) {
      if (isPublic) {
        const newClaims = claims.filter((el) => {
          if (claim === 'family_name given_name') {
            return !claim.split(' ').includes(el);
          }
          return el !== claim;
        });
        setClaims(newClaims);
      } else {
        if (claim === 'family_name given_name') {
          const arrNames = claim.split(' ');
          setClaims([...claims, ...arrNames]);
        } else {
          setClaims([...claims, claim]);
        }
      }
    }
  };

  return (
    <div className={styles['lock-button']} style={{ marginRight: '0' }}>
      {isPublic ? (
        <Tooltip
          arrow
          title="Сделать публичным"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <WorldIcon className={styles['public-icon']} />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title="Сделать приватным"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <LockIcon />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      )}
      <Popover
        classes={{
          paper: clsx(styles.paper),
        }}
        onClose={closePopover}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          startIcon={<LockIcon />}
          className={clsx(styles['popover-button'], {
            [styles['selected-popover-button']]: true,
          })}
          onClick={(e) => {
            isPublic ? handleButtonClick(e) : closePopover(e);
          }}
        >
          <div className={styles['popover-info']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>Сделать приватным</Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Будет приватным по умолчанию для новых пользователей
            </Typography>
          </div>
        </Button>
        <Button
          startIcon={<GroupIcon />}
          className={styles['popover-button']}
          onClick={(e) => {
            isPublic ? closePopover(e) : handleButtonClick(e);
          }}
        >
          <div className={styles['popover-info']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>Сделать публичным</Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Будет публичным по умолчанию для новых пользователей
            </Typography>
          </div>
        </Button>
      </Popover>
    </div>
  );
};