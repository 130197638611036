import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import appSlice from './appSlice';
import { clientApi } from './services/client';
import { providerApi } from './services/provider';
import { userApi } from './services/user';
import userSlice from './userSlice';
import noticesSlice from './noticesSlice';
import { rtkQueryErrorLogger } from '../utils/middleware/errorHandler';
import { authApi } from './services/auth';
import { loggerApi } from './services/logger';
import { ldapApi } from './services/ldap';
import { ethereumApi } from './services/ethereum';
import { mailApi } from './services/mail';
import { ownerApi } from './services/owner';
import { smsApi } from './services/sms';
import { _1CApi } from './services/1C';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: {
    user: userSlice,
    app: appSlice,
    notices: noticesSlice,
    [clientApi.reducerPath]: clientApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [loggerApi.reducerPath]: loggerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [ldapApi.reducerPath]: ldapApi.reducer,
    [ethereumApi.reducerPath]: ethereumApi.reducer,
    [mailApi.reducerPath]: mailApi.reducer,
    [ownerApi.reducerPath]: ownerApi.reducer,
    [smsApi.reducerPath]: smsApi.reducer,
    [_1CApi.reducerPath]: _1CApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clientApi.middleware,
      providerApi.middleware,
      userApi.middleware,
      rtkQueryErrorLogger,
      loggerApi.middleware,
      ldapApi.middleware,
      _1CApi.middleware,
      ethereumApi.middleware,
      mailApi.middleware,
      ownerApi.middleware,
      smsApi.middleware,
    ),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(immutableCheck: false, serializableCheck: false),
});

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
