import React, { FC } from 'react';
import styles from './EditProvider.module.css';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { CLIENT_ID, PROJECT_NAME } from '../constants';
import { isOwner } from '../helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { TApplication } from '../redux/services/client';
import { MiscProviderType, OauthProviderType } from '../redux/services/provider';

type TProviderFooter = {
  clientId: string;
  role: TApplication['role'];
  type: MiscProviderType | OauthProviderType;
};

export const ProviderFooter: FC<TProviderFooter> = ({ clientId, role, type }) => {
  const { control } = useFormContext();
  return (
    <>
      {clientId === CLIENT_ID && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Публичный способ входа
            </Typography>
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Способ входа будет доступен для добавления в пользовательские приложения
            </Typography>
          </div>
          <Controller
            name="is_public"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Требовать подтверждение паролем
          </Typography>
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            После авторизации во внешней системе у пользователя будет запрошен пароль от{' '}
            {PROJECT_NAME}
          </Typography>
        </div>
        <Controller
          name="password_required"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Switch
              classes={{ root: styles['switch-root'] }}
              disableRipple
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </div>
      {(type === MiscProviderType.LDAP || type === MiscProviderType._1C) && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Запретить сброс пароля
            </Typography>
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Пользователь не сможет восстановить пароль от учетной записи для данного способа входа
            </Typography>
          </div>
          <Controller
            name="disable_password_reset"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      {isOwner(role) && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Авторегистрация пользователей
            </Typography>
            <Controller
              name="auto_registration"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  classes={{ root: styles['switch-root'] }}
                  disableRipple
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </div>
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Автоматическое создание профиля пользователя при первой авторизации
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Не требовать наличие электронной почты
            </Typography>
            <Controller
              name="auth_without_email"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  classes={{ root: styles['switch-root'] }}
                  disableRipple
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </div>
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Будет разрешен вход для профилей без электронной почты
          </Typography>
        </>
      )}
    </>
  );
};
