import { debounce } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import React, { FC, useState, useCallback } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useFormContext, useWatch } from 'react-hook-form';
import './ColorPicker.css';

const ColorPicker: FC<{
  componentName: string;
  colorName: string;
}> = ({ componentName, colorName }) => {
  const {
    register,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const setColorField = (color: string) =>
    setValue(`${componentName}.${colorName}`, color, { shouldDirty: true });
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const value = useWatch({ control, name: `${componentName}.${colorName}` });
  const handleClickAway = () => setOpen(false);
  const setColorThrottle = useCallback(debounce(setColorField, 50), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="wrapper">
        <div className="flex-container">
          <div
            style={{ position: 'relative', background: value }}
            onClick={handleClick}
            className="color-box"
          />
          <TextField
            {...register(`${componentName}.${colorName}`, {
              onChange: () => {
                if ((errors?.[componentName] as never)?.[colorName])
                  clearErrors(`${componentName}.${colorName}`);
              },
            })}
            className="text-field"
            inputProps={{ className: 'input' }}
            InputProps={{
              className: 'input-root',
            }}
            variant="standard"
          />
        </div>
        {open ? (
          <div className="color-picker">
            <HexColorPicker
              color={value}
              onChange={(color: string) => {
                setColorThrottle(color);
              }}
            />
            <div className="flex-container">
              <div style={{ background: value }} className="color-box" />
              <TextField
                className="text-field"
                inputProps={{ className: 'input' }}
                InputProps={{
                  className: 'input-root',
                }}
                {...register(`${componentName}.${colorName}`, {
                  onChange: () => {
                    if ((errors?.[componentName] as never)?.[colorName])
                      clearErrors(`${componentName}.${colorName}`);
                  },
                })}
                variant="standard"
              />
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default ColorPicker;
