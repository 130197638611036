import React, { useState } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { isOwner } from '../../helpers';
import { Roles } from '../../enums';

type OwnerRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  userRole?: Roles;
  isUserProfileLoading: boolean;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  userRole: state.user.userProfile.role,
  isUserProfileLoading: state.user.isUserProfileLoading,
});

const OwnerRouteComponent: React.FC<OwnerRouteProps> = ({
  children,
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  userRole,
  isUserProfileLoading,
  ...rest
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [roleIsOwner, setRoleIsOwner] = useState(false);
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);

  useEffect(() => {
    let cleanupFunction = false;

    const authorization = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
        }

        if (userRole) {
          if (isOwner(userRole)) {
            !cleanupFunction && setRoleIsOwner(true);
          }
        }
        if (!isUserProfileLoading) !cleanupFunction && setWait(false);
      } catch (e) {
        console.log('auth error: ' + e);
      }
    };
    authorization();

    return () => {
      cleanupFunction = true;
    };
  }, [location.pathname, isUserProfileLoading]);

  return wait ? (
    <LinearProgress />
  ) : (
    <Route
      {...rest}
      render={() => {
        if (isAuthorized && roleIsOwner) {
          if (redirectToFillProfile) return <Redirect to="fill-profile" />;
          return children;
        }
        return <Redirect to={'/applications'} />;
      }}
    />
  );
};

export const OwnerRoute = connect(mapStateToProps)(OwnerRouteComponent);
