import React, { FC } from 'react';
import { ResizablePanel } from '../applications/ResizablePanel';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styles from './UsersRightPanel.module.css';
import { RootState } from '../../redux/rootReducer';
import { ApplicationUserInfo } from '../applications/ApplicationUserInfo';

export const UsersRightPanel: FC<{ userId?: number; clientId?: string }> = ({
  userId,
  clientId,
}) => {
  const { isClientPanelOpen, isMobile } = useSelector((state: RootState) => state.app);

  const hidden = isMobile || isClientPanelOpen;

  return (
    <ResizablePanel>
      <Box className={clsx(styles.panel, { [styles['panel-hidden']]: hidden })}>
        <ApplicationUserInfo rightPanel={true} userIdProp={userId} clientIdProp={clientId} />
      </Box>
    </ResizablePanel>
  );
};
