import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import React, {
  FC,
  useEffect,
  useState,
  // #371 KeyboardEvent,
  useRef,
} from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TApplication,
  TClient,
  useUpdateApplicationMutation,
  clientApi,
} from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationFormChanged } from '../../redux/appSlice';
// #609 import { CustomRadioButton } from '../CustomRadioButton';
import { AddProvider } from '../AddProvider';
import { TOauthProvider, TMiscProvider } from '../../redux/services/provider';
import { isObjectEmpty, isUrl } from '../../helpers';
import { EditApplicationHeader } from './EditApplicationHeader';
import { EditApplicationFooter } from './EditApplicationFooter';
import { redirectUriSchema, logoutUriSchema, requestUriSchema } from './CreateApplication';

export type TWidgetColors = { button_color: string; link_color: string; font_color: string };

export type TEditAppicationInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  request_uris: {
    name: string;
    value: string;
  }[];
  response_types: string[];
  grant_types: string[];
  // #371 refresh_token_ttl: string;
  // #371 access_token_ttl: string;
  avatar: File | string | null;
  cover: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  hide_widget_header: boolean;
  hide_widget_footer: boolean;
  widget_title: string;
  client_id: string;
  client_secret: string;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
};

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255),
    domain: yup
      .string()
      // #520 .url('Неверный формат ссылки')
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirect_uris: yup.array().of(redirectUriSchema).required(),
    post_logout_redirect_uris: yup.array().of(logoutUriSchema).required(),
    request_uris: yup.array().of(requestUriSchema).required(),
    // #371
    // refresh_token_ttl: yup
    //   .number()
    //   .min(1800, 'Время жизни токена обновления не может быть меньше 1800 секунд')
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    // access_token_ttl: yup
    //   .number()
    //   .min(60, 'Время жизни токена доступа не может быть меньше 60 секунд')
    //   .max(
    //     ACCESS_TOKEN_TTL,
    //     `Время жизни токена доступа не может превышать ${ACCESS_TOKEN_TTL} секунд` +
    //       (ACCESS_TOKEN_TTL / 60 / 60 / 24 >= 1
    //         ? ` (${
    //             ACCESS_TOKEN_TTL / 60 / 60 / 24 +
    //             ' ' +
    //             getDeclinationByNumber(ACCESS_TOKEN_TTL / 60 / 60 / 24, ['день', 'дня', 'дней'])
    //           }).`
    //         : ''),
    //   )
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    client_id: yup
      .string()
      .required('Обязательное поле')
      .matches(/^[^\n ]*$/, {
        message: 'Идентификатор не может содержать пробелы',
      })
      .matches(/^[A-Za-z0-9_-]+$/, {
        message:
          'Может содержать латинские буквы (a-z), цифры (0-9), дефис (-) и нижнее подчёркивание (_)',
      }),
    client_secret: yup.string().required('Обязательное поле'),
  })
  .required();

type TEditApplication = {
  selectedClient: TClient;
  role: TApplication['role'];
  userId: string;
};

export const EditApplication: FC<TEditApplication> = ({ selectedClient, role, userId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // #609 const [isVisible, setIsVisible] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  // #371 const [showRefreshTokenInput, setShowRefreshTokenInput] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<
    TOauthProvider | TMiscProvider | undefined
  >(undefined);

  const savedCallback = useRef<() => void>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );
  const [updateApplication, result] = useUpdateApplicationMutation();
  const methods = useForm<TEditAppicationInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient?.redirect_uris?.map((uri) => ({ value: uri })) || [
        { value: '' },
      ],
      post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris.length
        ? selectedClient.post_logout_redirect_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      request_uris: selectedClient?.request_uris.length
        ? selectedClient.request_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      response_types: selectedClient?.response_types,
      grant_types: selectedClient?.grant_types,
      require_auth_time: selectedClient?.require_auth_time,
      require_signed_request_object: selectedClient?.require_signed_request_object,
      avatar: selectedClient?.avatar,
      cover: selectedClient?.cover,
      client_id: selectedClient.client_id,
      client_secret: selectedClient.client_secret,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  // #609
  // useEffect(() => {
  //   if (selectedClient) {
  //     setIsVisible(selectedClient.is_visible);
  //   }
  // }, [selectedClient]);

  useEffect(() => {
    if (result.isSuccess) history.push('/applications');
  }, [result]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TEditAppicationInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;
    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (
        acc: Partial<
          Omit<TApplication['client'], 'avatar' | 'cover'> & {
            avatar: File | null;
            cover: File | null;
          }
        >,
        field,
      ) => {
        if (
          field === 'post_logout_redirect_uris' ||
          field === 'redirect_uris' ||
          field === 'request_uris'
        ) {
          acc[field] = data[field].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []);
        } else if (field === 'avatar' || field === 'cover') {
          if (typeof data[field] !== 'string') acc[field] = data[field] as File;
        } else if (field === 'widget_colors') acc.widget_colors = data.widget_colors;
        else if (
          field === 'show_avatar_in_widget' ||
          field === 'hide_widget_footer' ||
          field === 'hide_widget_header' ||
          field === 'require_auth_time' ||
          field === 'require_signed_request_object'
        )
          acc[field] = data[field];
        else if (field === 'response_types' || field === 'grant_types') acc[field] = data[field];
        else acc[field] = data[field];
        return acc;
      },
      {},
    );

    await updateApplication({
      currentClientId: selectedClient?.client_id,
      params: {
        ...payload,
        registration_access_token: selectedClient?.registration_access_token?.jti,
        redirect_uris: payload?.redirect_uris || selectedClient?.redirect_uris,
        post_logout_redirect_uris:
          payload?.post_logout_redirect_uris || selectedClient?.post_logout_redirect_uris,
        request_uris: payload?.request_uris || selectedClient?.request_uris,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          if (applicationFormChanged) {
            savedCallback.current = () => history.goBack();
            return setSaveModalOpen(true);
          }
          history.goBack();
        }}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложение
      </Button>
      <FormProvider {...methods}>
        <form className={styles['create-client-form']} onSubmit={handleSubmit(onSubmit)}>
          <EditApplicationHeader
            coverSrc={coverSrc}
            setCoverSrc={setCoverSrc}
            avatarSrc={avatarSrc}
            selectedClient={selectedClient}
            setAvatarSrc={setAvatarSrc}
            setProviderModalOpen={setProviderModalOpen}
            setSelectedProvider={setSelectedProvider}
          />
          {/* #609
          <div className={styles.divider} />
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Публичность
          </Typography>
          <div className={styles['custom-checkbox-wrapper']}>
            <CustomRadioButton
              label={
                <div>
                  <Typography className={clsx('text-14', 'color-0B1641')}>
                    Скрытое приложение
                  </Typography>
                  <Typography className={clsx('text-12', 'color-858BA0')}>
                    Приложение нельзя найти в поиске
                  </Typography>
                </div>
              }
              checked={!isVisible}
              onClick={() => {
                setIsVisible(false);
              }}
            />
          </div>
          <div className={styles['custom-checkbox-wrapper']}>
            <CustomRadioButton
              label={
                <div>
                  <Typography className={clsx('text-14', 'color-0B1641')}>
                    Видимое приложение
                  </Typography>
                  <Typography className={clsx('text-12', 'color-858BA0', styles['input-subtitle'])}>
                    Приложение можно найти в поиске
                  </Typography>
                </div>
              }
              checked={isVisible}
              onClick={() => {
                setIsVisible(true);
              }}
            />
          </div> */}
          <EditApplicationFooter
            coverSrc={coverSrc}
            applicationFormChanged={applicationFormChanged}
            avatarSrc={avatarSrc}
            savedCallback={savedCallback}
            setSaveModalOpen={setSaveModalOpen}
          />
        </form>
      </FormProvider>
      <AddProvider
        role={role}
        close={() => setProviderModalOpen(false)}
        clearSelectedProvider={() => setSelectedProvider(undefined)}
        isOpen={providerModalOpen}
        selectedProvider={selectedProvider}
      />
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                savedCallback.current?.();
                dispatch(setApplicationFormChanged(false));
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
