import { TProviderColors } from '../../components/ProviderColor';
import { getObjectKeys } from '../../helpers';
import { providerApi, TMiscProvider } from './provider';

export const _1CProviderApi = providerApi.injectEndpoints({
  endpoints: (builder) => ({
    create1CProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TMiscProvider, 'avatar' | 'type'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `create_1c/${client_id}`,
        method: 'POST',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    update1CProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `update_1c/${client_id}`,
        method: 'PUT',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const { useCreate1CProviderMutation, useUpdate1CProviderMutation } = _1CProviderApi;

const providerParamsToFormData = (
  requestParams: Partial<
    Omit<TMiscProvider, 'avatar'> & {
      avatar?: File | null;
      provider_id: string;
    }
  > & { provider_colors?: TProviderColors; provider_title?: string },
) => {
  try {
    return getObjectKeys(requestParams).reduce((acc, key) => {
      if (key === 'avatar' && requestParams[key] !== undefined) {
        acc.append(key, requestParams[key] || '');
        return acc;
      }

      if (key === 'provider_colors') {
        if (requestParams.provider_colors) {
          getObjectKeys(requestParams.provider_colors).forEach((item) =>
            acc.append(item, requestParams.provider_colors?.[item] || ''),
          );

          return acc;
        }
        return acc;
      }

      acc.append(key, requestParams[key]?.toString() || '');

      return acc;
    }, new FormData());
  } catch (e) {
    console.log('providerParamsToFormData error: ' + (e as Error).message);
  }
};
