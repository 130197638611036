import React from 'react';
import { ReactComponent as CheckboxCheckedIcon } from '../../icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxIcon } from '../../icons/Checkbox.svg';
import styles from './CustomCheckbox.module.css';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import clsx from 'clsx';

export const CustomCheckbox = ({ className, disabled, indeterminate, ...props }: CheckboxProps) => {
  return (
    <Checkbox
      disabled={disabled}
      indeterminate={indeterminate}
      indeterminateIcon={<CheckboxIcon />}
      className={clsx(styles.checkbox, className, { [styles.indeterminate]: indeterminate })}
      icon={<CheckboxIcon className={clsx({ [styles['checkbox-disabled']]: disabled })} />}
      checkedIcon={<CheckboxCheckedIcon />}
      {...props}
    />
  );
};
