import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { MiscProviderType, OauthProviderType } from '../redux/services/provider';
import { CreateProvider } from './CreateProvider';
import { CreateProviderByTemplate } from './CreateProviderByTemplate';
import { CreateLdapProvider } from './CreateLdapProvider';
import { BACKEND_URL } from '../constants';
import { TApplication } from '../redux/services/client';
import { CreateEthereumProvider } from './CreateEthereumProvider';
import { CreateSmsProvider } from './CreateSmsProvider';
import { Create1CProvider } from './Create1CProvider';

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
  role: TApplication['role'];
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, close, role }) => {
  const ldapProviderAvatar = 'public/images/provider/ldap.svg';
  const aldProProviderAvatar = 'public/images/provider/ald-pro.png';
  const _1CProviderAvatar = 'public/images/provider/1c.svg';
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';
  const smsProviderAvatar = 'public/images/provider/sms.svg';

  const providers = [
    {
      type: OauthProviderType.YANDEX,
      name: 'Яндекс',
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      type: OauthProviderType.VK,
      name: 'ВКонтакте',
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      type: OauthProviderType.MAILRU,
      name: 'Mail.ru',
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      type: OauthProviderType.GOOGLE,
      name: 'Google',
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      type: OauthProviderType.KLOUD,
      name: 'Kloud.One',
      pathToAvatar: 'public/images/provider/kloud.svg',
    },
    {
      type: OauthProviderType.QRCODE,
      name: 'QR-код',
      pathToAvatar: 'public/images/provider/qrcode.svg',
    },
    {
      type: OauthProviderType.CUSTOM,
      name: '',
      pathToAvatar: '',
    },
    {
      type: MiscProviderType.LDAP,
      name: 'LDAP',
      pathToAvatar: ldapProviderAvatar,
    },
    {
      type: MiscProviderType.ALDPRO,
      name: 'ALDPRO',
      pathToAvatar: aldProProviderAvatar,
    },
    {
      type: MiscProviderType._1C,
      name: '1C',
      pathToAvatar: _1CProviderAvatar,
    },
    {
      type: MiscProviderType.ETHEREUM,
      name: 'Ethereum',
      pathToAvatar: ethereumProviderAvatar,
    },
    {
      type: MiscProviderType.SMS,
      name: 'СМС',
      pathToAvatar: smsProviderAvatar,
    },
  ];

  const [providerTemplate, setProviderTemplate] = useState({
    type: OauthProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
  });

  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormAldProOpen, setIsCreateFormAldProOpen] = useState(false);
  const [isCreateForm1COpen, setIsCreateForm1COpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);
  const [isCreateFormSmsOpen, setIsCreateFormSmsOpen] = useState(false);

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Drawer
        classes={{ paper: styles['drawer-paper'] }}
        BackdropProps={{ className: styles.backdrop }}
        onClose={handleClose}
        open={isOpen}
        anchor="right"
        variant="temporary"
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа
            </Typography>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['choose-provider']}>
            <Typography
              className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
            >
              Выберите шаблон способа входа
            </Typography>
            {providers.map((provider, index) => {
              return (
                <ListItem
                  key={Date.now() + index}
                  className={styles.provider}
                  onClick={() => {
                    if (provider.type === OauthProviderType.CUSTOM) {
                      setIsCreateFormOpen(true);
                    } else if (provider.type === MiscProviderType.LDAP) {
                      setIsCreateFormLdapOpen(true);
                    } else if (provider.type === MiscProviderType.ALDPRO) {
                      setIsCreateFormAldProOpen(true);
                    } else if (provider.type === MiscProviderType.ETHEREUM) {
                      setIsCreateFormEthereumOpen(true);
                    } else if (provider.type === MiscProviderType.SMS) {
                      setIsCreateFormSmsOpen(true);
                    } else if (provider.type === MiscProviderType._1C) {
                      setIsCreateForm1COpen(true);
                    } else if (
                      provider.type !== MiscProviderType.CREDENTIALS &&
                      provider.type !== MiscProviderType.EMAIL
                    ) {
                      setProviderTemplate({
                        ...providerTemplate,
                        type: provider.type,
                        name: provider.name,
                        pathToAvatar: provider.pathToAvatar,
                      });
                      setIsCreateFormTemplateOpen(true);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                </ListItem>
              );
            })}
          </div>
        </div>
      </Drawer>
      <CreateProviderByTemplate
        role={role}
        isOpen={isCreateFormTemplateOpen}
        close={() => setIsCreateFormTemplateOpen(false)}
        providerTemplate={providerTemplate}
      />
      <CreateProvider
        role={role}
        isOpen={isCreateFormOpen}
        close={() => setIsCreateFormOpen(false)}
      />
      <CreateLdapProvider
        role={role}
        pathToAvatar={ldapProviderAvatar}
        isOpen={isCreateFormLdapOpen}
        close={() => setIsCreateFormLdapOpen(false)}
        providerType={MiscProviderType.LDAP}
      />
      <CreateLdapProvider
        role={role}
        pathToAvatar={aldProProviderAvatar}
        isOpen={isCreateFormAldProOpen}
        close={() => setIsCreateFormAldProOpen(false)}
        providerType={MiscProviderType.ALDPRO}
      />
      <Create1CProvider
        role={role}
        pathToAvatar={_1CProviderAvatar}
        isOpen={isCreateForm1COpen}
        close={() => setIsCreateForm1COpen(false)}
      />
      <CreateEthereumProvider
        role={role}
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        close={() => setIsCreateFormEthereumOpen(false)}
      />
      <CreateSmsProvider
        pathToAvatar={smsProviderAvatar}
        role={role}
        isOpen={isCreateFormSmsOpen}
        close={() => setIsCreateFormSmsOpen(false)}
      />
    </>
  );
};
