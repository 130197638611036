import React, { FC } from 'react';
import { ReactComponent as RadioIcon } from '../icons/Radio.svg';
import { ReactComponent as RadioCheckedIcon } from '../icons/RadioChecked.svg';
import styles from './CustomRadioButton.module.css';
import { FormControlLabel, FormControlLabelProps, Radio } from '@mui/material';
import clsx from 'clsx';

export const CustomRadioButton: FC<Omit<FormControlLabelProps, 'control'>> = ({ ...props }) => {
  return (
    <FormControlLabel
      classes={{ root: styles.root, label: styles.label }}
      {...props}
      control={
        <Radio
          disableRipple
          className={clsx(styles.radio, props.className)}
          icon={<RadioIcon />}
          checkedIcon={<RadioCheckedIcon />}
        />
      }
    />
  );
};
