import { createTheme } from '@mui/material/styles';
import checkmarkSvg from '../../icons/CheckMark.svg';

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            textTransform: 'none',
          },
          '&.MuiIconButton-root:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '&.custom': {
            '& .MuiStepConnector-line': {
              borderColor: '#E7E8EC',
            },

            '& .MuiStep-root': {
              '&:first-child': {
                paddingLeft: 0,
              },
              '&:last-child': {
                paddingRight: 0,
              },
            },

            '& .MuiSvgIcon-root': {
              color: '#F1F1F4',
              '& .MuiStepIcon-text': {
                fill: '#3C4567',
              },
              '&.Mui-completed': {
                color: '#4C6AD4',
                '& .MuiStepIcon-text': {
                  fill: '#fff',
                },
              },
              '&.Mui-active': {
                color: '#4C6AD4',
                '& .MuiStepIcon-text': {
                  fill: '#fff',
                },
              },
            },

            '& .MuiStepLabel-labelContainer': {
              color: '#858BA0',
              ' .Mui-completed': {
                color: '#0B1641',
                fontWeight: 400,
              },

              ' .Mui-active': {
                color: '#0B1641',
                fontWeight: 400,
              },
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.custom': {
            '& .MuiInput-root': {
              padding: 0,

              ...((ownerState.multiline || ownerState.InputProps?.endAdornment) && {
                border: '1px solid #e7e8ec',
                borderRadius: 1,
              }),
              '& .MuiInputBase-input': {
                ...(!ownerState.multiline &&
                  !ownerState.InputProps?.endAdornment && {
                    border: '1px solid #e7e8ec',
                    borderRadius: 1,
                  }),
                borderRadius: 1,
                minHeight: 42,
                boxSizing: 'border-box',
                color: '#3c4567',
                padding: '1px 1px 1px 16px',
              },
              ...(!ownerState.multiline &&
                !ownerState.InputProps?.endAdornment && {
                  '&.Mui-focused .MuiInputBase-input': {
                    border: '2px solid #606783',
                    padding: '0px 0px 0px 15px',
                  },
                }),
              '&.Mui-focused': {
                borderColor: '#606783 !important',
                borderWidth: '2px',
              },
              '&.Mui-disabled': { background: '#ecedf0', color: '#858ba0' },
              '&.Mui-disabled .MuiInputBase-input': { border: 'none' },
              '&::before': { display: 'none' },
              '&::after': {
                display: 'none',
              },
              '& textarea.MuiInput-input': {
                minHeight: '26px !important',
                outline: 'none',
                resize: 'none',
                fontFamily: 'Inter',
                maxHeight: 200,
                overflow: 'auto !important',
                paddingTop: '4px !important',
              },
              '&.Mui-error .MuiInputBase-input': {
                borderColor: '#FC545C',
              },
            },
            '& .MuiFormHelperText-root': {
              marginTop: 8,
            },
            ...(!ownerState.multiline &&
              !ownerState.InputProps?.endAdornment && {
                '& .Mui-error .MuiInputBase-input': {
                  border: '2px solid #fc545c',
                },
              }),
            '& .Mui-error': {
              borderColor: '#fc545c',
              borderWidth: '2px',
            },
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 41,
          height: 20,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            transitionDuration: '300ms',
            transform: 'translateX(2px) translateY(2px)',
            '&.Mui-checked': {
              transform: 'translateX(23px) translateY(2px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#0B1641',
                opacity: 1,
                border: 0,
              },
              '& .MuiSwitch-thumb': {
                background: '#fff',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#000',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
            background: '#9DA2B3',
          },
          '& .MuiSwitch-track': {
            border: '1px solid #9DA2B3',
            borderRadius: '10px',
            backgroundColor: '#fff',
            opacity: 1,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          '&.custom-select': {
            paddingLeft: 55,
            display: 'block',
            position: 'relative',
          },
          '&.Mui-selected': {
            backgroundColor: '#f5f6f8 !important',
            '::after': {
              width: 24,
              height: 24,
              content: '""',
              position: 'absolute',
              left: 24,
              top: '50%',
              transform: 'translateY(-50%)',
              background: `url(${checkmarkSvg})`,
              backgroundSize: 'cover',
            },
          },
        }),
      },
    },

    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          minWidth: 0,
          '&.MuiButton-custom': {
            ...(ownerState.variant === 'custom' &&
              ownerState.endIcon && {
                paddingRight: 16,
                '&.Mui-focusVisible': { paddingRight: 14 },
                '&:active': { paddingRight: 16 },
              }),
            ...(ownerState.variant === 'custom' &&
              ownerState.endIcon &&
              !ownerState.children && {
                padding: 0,
                height: 40,
                width: 40,
                '&.Mui-focusVisible': { height: 36, width: 36, padding: 0 },
                '&:active': { height: 40, width: 40, padding: 0 },
                '& .MuiButton-endIcon': {
                  margin: 0,
                },
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }),
            ...(ownerState.variant === 'custom' &&
              ownerState.startIcon && {
                paddingLeft: 16,
                '&.Mui-focusVisible': { paddingLeft: 14 },
                '&:active': { paddingLeft: 16 },
              }),
            ...(ownerState.variant === 'custom' &&
              ownerState.startIcon &&
              !ownerState.children && {
                padding: 0,
                height: 40,
                width: 40,
                '&.Mui-focusVisible': { height: 36, width: 36, padding: 0 },
                '&:active': { height: 40, width: 40, padding: 0 },
                '& .MuiButton-endIcon': {
                  margin: 0,
                },
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }),
          },
        }),
        startIcon: {
          margin: 0,
        },
        endIcon: {
          margin: 0,
        },
      },
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            padding: '0 24px',
            fontSize: 14,
            lineHeight: '21px',
            color: '#fff',
            background: '#4C6AD4',
            borderRadius: 1,
            transition: 'none',
            height: 40,
            fontWeight: 400,
            boxSizing: 'content-box',
            '&.MuiButtonBase-root:hover': {
              background: '#3050C5',
            },
            '&.Mui-focusVisible': {
              padding: '0 22px',
              height: 36,
              border: '2px solid #0B1641',
            },
            '&:active': {
              padding: '0 24px',
              border: 'none',
              height: 40,
            },
            '&:disabled': {
              opacity: 0.6,
              color: '#fff',
            },
            '& .MuiButton-endIcon': {
              marginLeft: 8,
            },
            '& .MuiButton-startIcon': {
              marginRight: 8,
            },
          },
        },
        {
          props: { variant: 'custom', color: 'error' },
          style: {
            background: '#FC545C',
            '&.MuiButtonBase-root:hover': {
              background: '#DF2E37',
            },
          },
        },
        {
          props: { variant: 'custom', color: 'secondary' },
          style: {
            background: '#ECEDF0',
            color: '#0B1641',
            '&.MuiButtonBase-root:hover': {
              background: '#E7E8EC',
            },
          },
        },
        {
          props: { variant: 'custom2' },
          style: {
            borderRadius: 0,
            padding: 2,
            fontSize: 14,
            lineHeight: '21px',
            background: 'none',
            fontWeight: 400,
            color: '#4C6AD4',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
            '&.Mui-focusVisible': {
              border: '2px solid #0B1641',
              padding: 0,
            },
            '&:active': {
              border: 'none',
              padding: 2,
            },
            '&:disabled': {
              color: '#4C6AD4',
              opacity: 0.6,
            },
          },
        },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&.MuiButtonBase-root': {
            minHeight: 0,
          },
        },
      },
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    custom: true;
    custom2: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    custom: true;
    custom2: true;
  }
}
