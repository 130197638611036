import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import styles from './EditApplicationFields.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DescriptionField = ({ control }: { control: any }) => {
  const description = useWatch({ control, name: 'description' });
  return (
    <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
      {description?.length}/255 символов
    </Typography>
  );
};

export const RedirectUrisField = ({
  control,
  onClick,
  className,
  name,
  disabled,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  onClick: () => void;
  className?: string;
  name: string;
  disabled?: boolean;
}) => {
  const redirectUris = useWatch({ control, name });
  return (
    <Button
      variant="custom2"
      onMouseDown={onClick}
      disabled={!redirectUris?.[redirectUris.length - 1]?.value || disabled}
      className={className}
    >
      Добавить URI
    </Button>
  );
};
