import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './ApplicationInfoUsers.module.css';
import clsx from 'clsx';
import { TUser, useLazyGetUserCountQuery, useLazyGetUsersQuery } from '../../redux/services/client';
import { TAppSlice } from '../../redux/appSlice';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as RemoveIcon } from '../../icons/Close.svg';
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  RowMouseEventHandlerParams,
  Table,
  TableHeaderProps,
} from 'react-virtualized';
import { debounce } from '@mui/material';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getImageURL, getRoleName, isObjectEmpty } from '../../helpers';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { ChangePermissionsModal } from './ChangePermissionsModal';
import { UserPopover } from '../profile/UserPopover';

export type Order = 'asc' | 'desc';
export type TColumnNames = 'mainPage' | 'user' | 'role';
export type TApplicationInfoUsersProps = {
  selectedClientId: TAppSlice['selectedClientId'];
  selectedUser: { user: Partial<TUser>; role: string } | null;
  setSelectedUser: (user: { user: Partial<TUser>; role: string }) => void;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMenuOpen: TAppSlice['isMenuOpen'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  isMobile: TAppSlice['isMobile'];
  loggedUserId?: string;
  toggleUpdateUsers: TAppSlice['toggleUpdateUsers'];
};

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.app.isMenuOpen,
  isClientPanelOpen: state.app.isClientPanelOpen,
  clientPanelWidth: state.app.clientPanelWidth,
  isMobile: state.app.isMobile,
  loggedUserId: state.user.userProfile.id,
  toggleUpdateUsers: state.app.toggleUpdateUsers,
});

const ApplicationInfoUsersComponent: FC<TApplicationInfoUsersProps> = ({
  selectedClientId,
  selectedUser,
  setSelectedUser,
  isMenuOpen,
  isClientPanelOpen,
  clientPanelWidth,
  isMobile,
  loggedUserId,
  toggleUpdateUsers,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<TColumnNames>('user');
  const [users, setUsers] = useState<({ user: Partial<TUser>; role: string } | undefined)[]>([]);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserToActions, setSelectedUserToActions] = useState<Partial<TUser> | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [searchIconRight, setSearchIconRight] = useState(15);
  const [width, setWidth] = useState(0);
  const [animateWidth, setAnimateWidth] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<null | string>(null);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const isOpen = Boolean(anchorEl);
  const rowCount = users.length;
  const columnsCount = width > 400 ? 2 : 1;
  const mounted = useRef(false);
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const [getUsers] = useLazyGetUsersQuery();
  const [getUserCount] = useLazyGetUserCountQuery();

  useEffect(() => {
    setTimeout(() => {
      const scrollContainerHeight = document.getElementsByClassName(
        'ReactVirtualized__Grid__innerScrollContainer',
      )?.[0]?.clientHeight;
      const tableContainerHeight = document.getElementsByClassName(
        'ReactVirtualized__Table__Grid',
      )?.[0]?.clientHeight;
      setSearchIconRight((scrollContainerHeight || 0) <= tableContainerHeight ? 15 : 26);
    }, 100);
  }, [
    searchValue,
    selectedClientId,
    isSearchInputOpen,
    isMenuOpen,
    isClientPanelOpen,
    clientPanelWidth,
    isMobile,
    selectedUser,
  ]);

  useEffect(() => {
    if (users.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [users]);

  useEffect(() => {
    if (!mounted.current) {
      setAnimateWidth(false);
      mounted.current = true;
    } else {
      setAnimateWidth(true);
      return () => setAnimateWidth(false);
    }
  }, [isClientPanelOpen, isMenuOpen]);

  useEffect(() => {
    if (users[0] && !selectedUser) setSelectedUser(users[0]);

    if (selectedUser) {
      const user = users.find((user) => user?.user.id === selectedUser?.user.id);
      if (user) setSelectedUser(user);
    }
  }, [selectedClientId, users]);

  useEffect(() => {
    const setUsersOnStart = async () => {
      setSearchValue('');
      const { data: countData } = await getUserCount({
        selectedAppId: selectedClientId || '',
        search_string: '',
      });

      setUsers(new Array(Number(countData?.userCount)).fill(undefined));
    };
    setUsersOnStart();
  }, [selectedClientId, toggleUpdateUsers]);

  useEffect(() => {
    setUsers((users) => users.map(() => undefined));
  }, [order, orderBy]);

  useEffect(() => {
    const onResize = () => {
      setWidth(
        window.innerWidth -
          33 -
          +!isMobile * (280 * +isMenuOpen + clientPanelWidth * +!isClientPanelOpen),
      );
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isMenuOpen, isClientPanelOpen, clientPanelWidth, isMobile]);

  const onSearch = async (searchString: string) => {
    const { data: countData } = await getUserCount({
      selectedAppId: selectedClientId || '',
      search_string: searchString,
    });
    if (countData !== undefined) {
      const { userCount } = countData;
      setUsers(new Array(Number(userCount)).fill(undefined));
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 200), [selectedClientId, order, orderBy]);

  const rowGetter = ({ index }: { index: number }) => {
    return users?.[index] || {};
  };

  const onRowClick = (rowInfo: RowMouseEventHandlerParams) => {
    setSelectedUser(rowInfo.rowData);
  };

  const isRowLoaded = ({ index }: { index: number }) => !!users[index];

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
  }) => {
    const { data } = await getUsers({
      client_id: selectedClientId || '',
      number_of_records: String(stopIndex - startIndex + 1),
      number_of_skip: String(startIndex),
      sort_by: orderBy === 'user' ? 'nickname' : orderBy,
      sort_direction: order,
      search_string: searchString === undefined ? searchValue : searchString,
    });

    setUsers((users) =>
      users.map((user, index) => {
        if (index < startIndex || index > stopIndex) return user;
        return data?.[index - startIndex];
      }),
    );
  };

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    return clsx(styles.row, {
      [styles['content-row']]: index !== -1,
      [styles['header-row']]: index === -1,
      [styles['selected-row']]:
        selectedUser &&
        selectedUser.user.id === (rowGetter?.({ index }) as { user: TUser }).user?.id &&
        index !== -1,
    });
  };

  const handleRequestSort = (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>, user: Partial<TUser>) => {
    event.stopPropagation();
    if (event.clientY + 180 > document.body.clientHeight) setPopoverOriginTop(true);
    else setPopoverOriginTop(false);
    setSelectedUserToActions(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUserToActions(null);
  };

  const headerRenderer = ({ label, dataKey }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(styles['table-cell'], styles['flex-container'], styles['header-cell'])}
        variant="head"
        align="left"
      >
        <Button
          onClick={() => handleRequestSort(dataKey as TColumnNames)}
          className={styles['header-button']}
        >
          <Typography className={clsx('text-14', 'color-858BA0')}>{label}</Typography>
        </Button>
        {dataKey === orderBy && (
          <ArrowTopIcon className={clsx({ [styles['rotate-180']]: !(order === 'asc') })} />
        )}
      </TableCell>
    );
  };
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles['animate-width']]: animateWidth,
      })}
    >
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
      >
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer>
              {({ height }) => {
                return (
                  <Table
                    headerClassName={styles.header}
                    onRowClick={onRowClick}
                    gridClassName={'users-table__grid'}
                    id={'table'}
                    height={height}
                    width={width}
                    rowHeight={80}
                    headerHeight={56}
                    rowCount={users.length}
                    rowGetter={rowGetter}
                    rowClassName={getRowClassName}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                  >
                    <Column
                      className={styles.column}
                      label="Имя"
                      width={width / columnsCount - 30}
                      key="name"
                      headerRenderer={(headerProps) =>
                        headerRenderer({
                          ...headerProps,
                          columnIndex: 0,
                        })
                      }
                      cellRenderer={({ cellData, rowData }) => {
                        const name = cellData?.nickname
                          ? cellData.nickname
                          : (
                              (cellData?.given_name || '') +
                              ' ' +
                              (cellData?.family_name || '')
                            ).trim();
                        return (
                          <TableCell
                            className={clsx(styles['table-cell'], styles['flex-container'])}
                            component="div"
                            variant="body"
                            align="left"
                          >
                            <div>
                              {isObjectEmpty(rowData) ? (
                                <Skeleton width={50} />
                              ) : (
                                <div className={styles['flex-container']}>
                                  <div
                                    className={styles['app-icon-wrapper']}
                                    style={{
                                      backgroundImage: `url(${getImageURL(cellData?.picture)})`,
                                    }}
                                  >
                                    {!cellData.picture && (
                                      <div className={styles['app-icon-default']}>
                                        {cellData?.nickname
                                          ?.split(' ')
                                          .map((name: string) => name[0]?.toUpperCase())
                                          .join('')}
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <Typography
                                      style={{
                                        maxWidth: width / columnsCount - 70 - 100 / columnsCount,
                                        transition: '0.5s ease max-width',
                                      }}
                                      className={clsx(
                                        styles['overflow-ellipsis'],
                                        'text-14',
                                        'color-4C6AD4',
                                      )}
                                    >
                                      <Link
                                        style={{
                                          display: 'block',
                                          width: '100%',
                                          textDecoration: 'none',
                                        }}
                                        className={clsx('color-4C6AD4')}
                                        to={{
                                          pathname:
                                            Number(loggedUserId) === rowData?.user?.id
                                              ? '/profile'
                                              : `/application/user/${selectedClientId}/${rowData?.user?.id}`,
                                          state: { prevPath: location.pathname },
                                        }}
                                      >
                                        {!name &&
                                        !cellData.public_profile_claims?.includes('nickname')
                                          ? 'Имя скрыто пользователем'
                                          : name || 'Нет имени'}
                                      </Link>
                                    </Typography>
                                    <Typography
                                      style={{
                                        maxWidth: width / columnsCount - 70 - 100 / columnsCount,
                                      }}
                                      className={clsx(
                                        styles['overflow-ellipsis'],
                                        'text-12',
                                        'color-858BA0',
                                      )}
                                    >
                                      ID {cellData.id}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TableCell>
                        );
                      }}
                      dataKey="user"
                    />
                    <Column
                      className={styles.column}
                      label="Права"
                      width={columnsCount > 1 ? width * 0.5 - 30 : 0}
                      headerRenderer={(headerProps) =>
                        headerRenderer({
                          ...headerProps,
                          columnIndex: 1,
                        })
                      }
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <TableCell
                            className={clsx(styles['table-cell'], styles['flex-container'])}
                            component="div"
                            variant="body"
                            align="left"
                          >
                            <Typography
                              style={{ maxWidth: width * 0.5 - 40 }}
                              className={clsx(styles['overflow-ellipsis'], 'text-14')}
                            >
                              {isObjectEmpty(rowData) ? (
                                <Skeleton width={50} />
                              ) : (
                                getRoleName(cellData)
                              )}
                            </Typography>
                          </TableCell>
                        );
                      }}
                      dataKey="role"
                    />
                    <Column
                      headerRenderer={() => {
                        return (
                          <>
                            {isSearchInputOpen ? (
                              <ClickAwayListener onClickAway={() => setIsSearchInputOpen(false)}>
                                <div className={styles['search-wrapper']}>
                                  <TextField
                                    inputProps={{ className: styles.input }}
                                    InputProps={{
                                      className: styles['input-root'],
                                    }}
                                    variant="standard"
                                    value={searchValue}
                                    fullWidth
                                    onChange={(e) => {
                                      onSearchDebounce(e.target.value);
                                      setSearchValue(e.target.value);
                                    }}
                                  />
                                  <SearchIcon className={styles['search-icon']} />
                                  <IconButton
                                    onClick={() => {
                                      onSearch('');
                                      setSearchValue('');
                                    }}
                                    disabled={!searchValue}
                                    className={styles['delete-icon-button']}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </div>
                              </ClickAwayListener>
                            ) : (
                              <IconButton
                                onClick={() => setIsSearchInputOpen(true)}
                                className={styles['search-icon-button']}
                                style={{
                                  right: searchIconRight,
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            )}
                          </>
                        );
                      }}
                      dataKey="key"
                      className={styles['actions-button-column']}
                      cellRenderer={({ rowData }) => (
                        <TableCell
                          component="div"
                          className={clsx(styles['table-cell'], styles['flex-container'])}
                          variant="head"
                          align="right"
                        >
                          <UserPopover
                            open={isOpen && rowData?.user?.id === selectedUserToActions?.id}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: popoverOriginTop ? 'top' : 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: popoverOriginTop ? 'bottom' : 'top',
                              horizontal: 'right',
                            }}
                            classes={{
                              paper: clsx(styles.paper, styles['popover-paper'], {
                                [styles['horizontal-direction-top']]: popoverOriginTop,
                              }),
                            }}
                            userId={rowData?.user?.id}
                            clientId={selectedClientId}
                            loggedUserId={loggedUserId}
                            setChangePermissionsModalOpen={setChangePermissionsModalOpen}
                          />
                          <IconButton
                            onClick={(event) => handleOpenPopover(event, rowData?.user)}
                            className={clsx(styles['open-actions-icon'], {
                              [styles['active-actions-icon']]:
                                rowData?.user?.id === selectedUser?.user?.id && isOpen,
                            })}
                            style={{
                              position: 'absolute',
                              right: 8,
                            }}
                          >
                            <ActionsIcon />
                          </IconButton>
                        </TableCell>
                      )}
                      width={40}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          );
        }}
      </InfiniteLoader>
      <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
        <ChangePermissionsModal
          selectedClientId={selectedClientId}
          selectedUserToActions={selectedUserToActions}
          users={users}
          closeChangePermissionModal={closeChangePermissionModal}
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          setChangePermissionsModalOpen={setChangePermissionsModalOpen}
        />
      </Modal>
    </div>
  );
};

export const ApplicationInfoUsers = connect(mapStateToProps)(ApplicationInfoUsersComponent);
