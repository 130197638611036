import React, { ChangeEvent, FC, Dispatch, MutableRefObject } from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { useWatch, FormProvider, useFormContext } from 'react-hook-form';
import { WidgetColor } from '../WidgetColor';
import { TEditAppicationInputs } from './EditApplication';

type TEditApplicationFooter = {
  avatarSrc: string | null;
  coverSrc: string | null;
  setSaveModalOpen: Dispatch<boolean>;
  applicationFormChanged: boolean;
  savedCallback: MutableRefObject<(() => void) | undefined>;
};

export const EditApplicationFooter: FC<TEditApplicationFooter> = ({
  avatarSrc,
  setSaveModalOpen,
  applicationFormChanged,
  savedCallback,
  coverSrc,
}) => {
  const methods = useFormContext<TEditAppicationInputs>();
  const { register, control, setValue } = methods;

  const showAvatar = useWatch({ control, name: 'show_avatar_in_widget' });
  const hideHeader = useWatch({ control, name: 'hide_widget_header' });
  const hideFooter = useWatch({ control, name: 'hide_widget_footer' });

  const history = useHistory();
  return (
    <>
      <div className={styles.divider} />
      <Typography
        className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
      >
        Внешний вид виджета
      </Typography>
      <div className={styles['switch-wrapper']}>
        <Typography className={clsx('text-14', 'color-0B1641')}>
          Показывать логотип приложения на виджете
        </Typography>
        <Switch
          checked={showAvatar}
          {...register('show_avatar_in_widget', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setValue('show_avatar_in_widget', event.target.checked, { shouldDirty: true });
            },
          })}
        />
      </div>
      <div className={styles['switch-wrapper']}>
        <Typography className={clsx('text-14', 'color-0B1641')}>Скрыть шапку</Typography>
        <Switch
          checked={hideHeader}
          {...register('hide_widget_header', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setValue('hide_widget_header', event.target.checked, { shouldDirty: true });
            },
          })}
        />
      </div>
      <div className={styles['switch-wrapper']}>
        <Typography className={clsx('text-14', 'color-0B1641')}>Скрыть подвал</Typography>
        <Switch
          checked={hideFooter}
          {...register('hide_widget_footer', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setValue('hide_widget_footer', event.target.checked, { shouldDirty: true });
            },
          })}
        />
      </div>
      <FormProvider {...methods}>
        <WidgetColor
          coverSrc={coverSrc}
          name={useWatch({ control, name: 'name' })}
          avatarSrc={avatarSrc}
          showAvatar={showAvatar}
          hideFooter={hideFooter}
          hideHeader={hideHeader}
        />{' '}
      </FormProvider>
      <div className={styles['submit-buttons']}>
        <Button
          onClick={() => {
            if (applicationFormChanged) {
              savedCallback.current = () => history.goBack();
              return setSaveModalOpen(true);
            }
            history.goBack();
          }}
          variant="custom"
          color="secondary"
        >
          Отмена
        </Button>
        <Button
          className={styles['create-button']}
          type="submit"
          variant="custom"
          disabled={!applicationFormChanged}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};
