import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { FC, MouseEvent, ReactElement, useState } from 'react';
import { ReactComponent as WorldIcon } from '../../icons/World.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import styles from './PublicStatusPopover.module.css';
import { useChangeClaimPrivacyMutation } from '../../redux/services/user';
import { TUserProfile } from '../../redux/userSlice';

type PublicStatusPopoverProps = {
  isPublic: boolean;
  claims?: string;
  userId?: string;
  disabled?: boolean;
  publicExternalAccounts?: TUserProfile['public_external_accounts_ids'];
};

export const PublicStatusPopover: FC<PublicStatusPopoverProps> = ({
  isPublic,
  claims,
  publicExternalAccounts,
  disabled,
  userId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [changeClaimPrivacy] = useChangeClaimPrivacyMutation();

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleButtonClick = async (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    closePopover(event);
    if (userId) {
      if (claims) return changeClaimPrivacy({ claims, isPublic, userId });
      if (publicExternalAccounts)
        return changeClaimPrivacy({ userId, isPublic, publicExternalAccounts });
    }
  };

  return (
    <div className={clsx(disabled ? styles['lock-button-disabled'] : styles['lock-button'])}>
      {isPublic ? (
        <Tooltip
          arrow
          title="Доступно всем"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <WorldIcon className={styles['public-icon']} />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title="Доступно только вам"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <LockIcon />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      )}
      {!disabled && (
        <Popover
          classes={{
            paper: clsx(styles.paper),
          }}
          onClose={closePopover}
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            startIcon={<LockIcon />}
            className={clsx(styles['popover-button'], {
              [styles['selected-popover-button']]: !isPublic,
            })}
            onClick={(e) => {
              isPublic ? handleButtonClick(e) : closePopover(e);
            }}
          >
            <div className={styles['popover-info']}>
              <Typography className={clsx('text-14', 'color-0B1641')}>
                Доступно только вам
              </Typography>
              <Typography className={clsx('text-12', 'color-858BA0')}>
                Данные конфиденциальны и видны только вам
              </Typography>
            </div>
          </Button>
          <Button
            startIcon={<GroupIcon />}
            className={clsx(styles['popover-button'], {
              [styles['selected-popover-button']]: isPublic,
            })}
            onClick={(e) => {
              isPublic ? closePopover(e) : handleButtonClick(e);
            }}
          >
            <div className={styles['popover-info']}>
              <Typography className={clsx('text-14', 'color-0B1641')}>
                Доступно по запросу
              </Typography>
              <Typography className={clsx('text-12', 'color-858BA0')}>
                Доступ к данным будет предоставлен после вашего согласия
              </Typography>
            </div>
          </Button>
        </Popover>
      )}
    </div>
  );
};
