import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL, CLIENT_ID } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TApplication, TClient, TSettings } from './client';

export const ownerApi = createApi({
  reducerPath: 'ownerApi',
  tagTypes: ['ExternalAccounts', 'Licenses', 'AvailableUsersCount'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/owner/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    changeMainEmailWithoutConfirmation: builder.mutation<
      void,
      {
        user_id: string;
        email: string;
      }
    >({
      query: ({ user_id, email }) => {
        return {
          url: `change_main_email/${user_id}`,
          method: 'PUT',
          body: '&email=' + email,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    addMailOrPhoneWithoutConfirmation: builder.mutation<
      void,
      {
        user_id: string;
        identifier: string;
      }
    >({
      query: ({ user_id, identifier }) => {
        return {
          url: `/mail_or_phone/${user_id}`,
          method: 'PUT',
          body: 'identifier=' + identifier,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    getLicenses: builder.query<string[], void>({
      query: () => `licenses/${CLIENT_ID}`,
      providesTags: [{ type: 'Licenses', id: 'LIST' }],
    }),

    activateLicense: builder.mutation<void, { license: string }>({
      query: (body) => ({
        method: 'POST',
        url: `activate_license/${CLIENT_ID}`,
        body,
      }),
      invalidatesTags: [
        { type: 'Licenses', id: 'LIST' },
        { type: 'AvailableUsersCount', id: 'LIST' },
      ],
    }),

    getAvailableUsersCount: builder.query<number, void>({
      query: () => `available_users_count/${CLIENT_ID}`,
      transformResponse: ({ count }: { count: number | string }) => {
        if (count === 'Infinity') return Infinity;
        if (typeof count !== 'number') {
          console.log('getAvailableUsersCount error: invalid users count');
          return 0;
        }
        return count;
      },
      providesTags: [{ type: 'AvailableUsersCount', id: 'LIST' }],
    }),

    getAllApplications: builder.query<
      TApplication[],
      {
        user_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: (body) => {
        return {
          url: `/get_all_clients/${body.user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        };
      },
    }),

    getAllApplicationsCount: builder.query<number, { userId: string; searchString?: string }>({
      query: ({ userId, searchString }) => {
        return {
          url: `/count_all_clients/${userId}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { search_string: searchString },
        };
      },
    }),

    blockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `block/${CLIENT_ID}`,
          method: 'PUT',
          body,
        };
      },
    }),

    unblockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `unblock/${CLIENT_ID}`,
          method: 'PUT',
          body,
        };
      },
    }),

    getActiveUsersCount: builder.mutation<
      { active_users_count: number; users_count: number },
      { search_string?: string }
    >({
      query: (body) => ({ url: `active-users-count/${CLIENT_ID}`, method: 'POST', body }),
    }),

    deleteUsersInfo: builder.mutation<
      { apps: { client: TClient; onlyEditor: boolean }[]; users_ids: number[] },
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({ url: `delete-users-info/${CLIENT_ID}`, method: 'POST', body }),
    }),

    deleteUsers: builder.mutation<
      { errors: string[] },
      { apps_ids?: string[] | null; delete_apps_with_user?: boolean; checked_ids: number[] }
    >({
      query: (body) => ({ method: 'DELETE', body, url: `users/${CLIENT_ID}` }),
    }),

    deleteUsersSessions: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({ method: 'DELETE', body, url: `all_users_sessions/${CLIENT_ID}` }),
    }),

    editSettings: builder.mutation<void, TSettings>({
      query: (body) => ({ method: 'PUT', body, url: `settings/${CLIENT_ID}` }),
    }),
  }),
});

export const {
  useAddMailOrPhoneWithoutConfirmationMutation,
  useChangeMainEmailWithoutConfirmationMutation,
  useGetAvailableUsersCountQuery,
  useGetLicensesQuery,
  useActivateLicenseMutation,
  useLazyGetAllApplicationsCountQuery,
  useLazyGetAllApplicationsQuery,
  useBlockUsersMutation,
  useUnblockUsersMutation,
  useGetActiveUsersCountMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useEditSettingsMutation,
} = ownerApi;
