import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const _1CApi = createApi({
  reducerPath: '1CApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/1c/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    get1CCredentials: builder.query<
      { admin_login: string; admin_password: string; secret: string },
      { client_id: string; provider_id: string }
    >({
      query: ({ client_id, provider_id }) => ({
        url: `/credentials/${client_id}/${provider_id}`,
      }),
    }),
  }),
});

export const { useLazyGet1CCredentialsQuery } = _1CApi;
