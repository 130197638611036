import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const authApi = createApi({
  reducerPath: 'AuthApi',
  tagTypes: ['Api'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/auth/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),

  endpoints: (builder) => ({
    deleteAllSession: builder.query({
      query: (userId) => ({
        method: 'DELETE',
        url: `/all_sessions/${userId}`,
        credentials: 'include',
      }),
    }),
  }),
});

export const { useLazyDeleteAllSessionQuery } = authApi;
