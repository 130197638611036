import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';
import { TApplication } from '../../redux/services/client';
import { IconWrapper } from '../IconWrapper';
import styles from './ApplicationInfoProfile.module.css';
import { ReactComponent as UrlIcon } from '../../icons/Url.svg';
import { ReactComponent as WatchIcon } from '../../icons/Watch.svg';
import { ReactComponent as InfoIcon } from '../../icons/Info.svg';
// #609 import { ReactComponent as EyeIcon } from '../../icons/Eye.svg';
// #609 import { ReactComponent as EyeOffIcon } from '../../icons/EyeOff.svg';
import { ReactComponent as CalendarIcon } from '../../icons/Calendar2.svg';
import { createFullDateString, isOwnerOrEditor } from '../../helpers';
import { Link } from '@mui/material';
import { getScopeProps } from '../../utils/getScopeProps';

type TApplicationInfoProfileProps = {
  application?: TApplication;
  scopesData?: { scopes?: string; createdAt?: string };
};

export const ApplicationInfoProfile: FC<TApplicationInfoProfileProps> = ({
  application,
  scopesData,
}) => {
  const { scopes, createdAt } = scopesData || {};
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles['admin-wrapper']]: isOwnerOrEditor(application?.role),
      })}
    >
      <div className={styles.info}>
        <Typography className={clsx('header-3', 'font-golos', styles['info-title'])}>
          Информация о приложении
        </Typography>
        {application?.client?.description && (
          <div className={styles['flex-container']}>
            <IconWrapper>
              <InfoIcon className={styles['info-icon']} />
            </IconWrapper>
            <div className={styles['client-description-wrapper']}>
              <Typography
                className={clsx(
                  'text-14',
                  'color-0B1641',
                  styles['info-item'],
                  styles['client-description'],
                )}
              >
                {application.client?.description}
              </Typography>
            </div>
          </div>
        )}

        {application?.client?.domain && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <UrlIcon />
            </IconWrapper>
            <Link
              href={application.client?.domain}
              underline="hover"
              className={clsx('text-14', 'color-4C6AD4', styles['info-item'])}
            >
              {application.client?.domain}
            </Link>
          </div>
        )}
        {createdAt && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <WatchIcon />
            </IconWrapper>
            <Typography className={clsx('text-14', 'color-0B1641', styles['info-item'])}>
              Доступ предоставлен {createFullDateString(new Date(createdAt))}
            </Typography>
          </div>
        )}
        {isOwnerOrEditor(application?.role) && (
          <>
            {/* #609
            <div className={clsx(styles['flex-container'], styles['align-center'])}>
              <IconWrapper>
                {application?.client.is_visible ? (
                  <EyeIcon className={styles['eye-icon']} />
                ) : (
                  <EyeOffIcon />
                )}
              </IconWrapper>
              <div>
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  {application?.client.is_visible ? 'Видимое приложение' : 'Скрытое приложение'}
                </Typography>
                <Typography className={clsx('text-12', 'color-858BA0')}>
                  {application?.client.is_visible
                    ? 'Приложение можно найти в поиске'
                    : 'Приложение нельзя найти в поиске'}
                </Typography>
              </div>
            </div> */}
            {application?.client.created_at && (
              <div className={clsx(styles['flex-container'], styles['align-center'])}>
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  Создано {createFullDateString(new Date(application.client.created_at))}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      {scopes && (
        <div className={styles.info}>
          <Typography className={clsx('header-3', 'font-golos', styles['info-title'])}>
            Предоставленные разрешения
          </Typography>
          {scopes?.split(' ').map((scope, index) => {
            const scopeProps = getScopeProps(scope);
            return (
              scopeProps && (
                <div key={index} className={clsx(styles['flex-container'], styles['align-center'])}>
                  <div className={styles['info-icon-wrapper']}>
                    <scopeProps.icon />
                  </div>
                  <Typography className={clsx('text-14', 'color-0b1641', styles['info-item'])}>
                    {scopeProps.description}
                  </Typography>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};
