import React, { FC } from 'react';
import styles from './CustomPopoverButton.module.css';
import { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import clsx from 'clsx';

export const CustomPopoverButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      {...props}
      className={clsx(styles['popover-button'], 'text-14', 'color-0B1641', className)}
    />
  );
};
